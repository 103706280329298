@import "normalize";
@import "icomoon";
@import "tomorrow-night";
@import "rangeslider";

// Global vars
$sans-serif: proxima-nova, "Proxima Nova", sans-serif;
$monospace: Inconsolata, monospace;
$text-color-dark: lighten(black, 30%);
$text-color-light: darken(white, 10%);

// Palette
$dark-blue: #2C3E50;
$dark-red: #E74C3C;

// Global tweaks & fixes
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-family: $sans-serif;
  font-size: 16px;
  text-align: center;
  color: $text-color-dark;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 1.15rem;
  font-weight: normal;
  margin: 3rem 0;
}

a, a:visited {
  color: #225378;
  cursor: pointer;
  transition: color 0.2s ease;
}

a.button {
  display: inline-block;
  margin: 1rem 0;
  padding: 0.5rem;
  background: white;
  border: 1px solid darken(white, 20%);
  border-radius: 5px;
  color: $text-color-dark;
  user-select: none;
  &:hover {
    background: darken(white, 1%);
  }
}

.dark a, .dark a:visited {
  color: #A2E2E4;
}

code {
  font-family: $monospace;
  font-size: 14px;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 3rem 0;
  code {
    text-align: left;
    display: inline-block;
    padding: 1rem;
    border-radius: 5px;
  }
}

p {
  max-width: 400px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  // line-height: 1.3;
}

.clearfix {
  &:before, &:after {
    content: ' ';
    display: table;
  }
}


.component-hero {
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  overflow: hidden;
  background: #99C8DF url(images/hero.jpg) center center;
  background-size: cover;

  //fix for bad font smoothing in chrome
  -webkit-font-smoothing: antialiased;

  .content {
    max-width: 100%;
  }

  h1 {
    font-size: 5rem;
    margin: 0 0;
    a {
      color: $text-color-dark;
    }
    a:hover {
      color: lighten($text-color-dark, 15%);
    }
  }

  p {
    font-size: 1.5rem;
    text-align: center;
    max-width: 100%;
  }

  canvas, img.hero-placeholder {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
  }

  .fancybutton {
    display: inline-block;
    font-size: 18px;
    text-transform: uppercase;
    padding: 5px 10px;
    margin: 5px 10px;
    color: $text-color-dark;
    vertical-align: middle;
    text-decoration: none;
    transition: color 0.2s ease;
    user-select: none;

    &:hover {
      color: lighten($text-color-dark, 10%);
    }

    .icon {
      font-size: 22px;
    }
  }

  .arrow-down {
    position: absolute;
    left: 50%;
    bottom: 50px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
    display: block;
    height: 3rem;
    width: 3rem;
    background: white;
    border-radius: 50%;
    transition: all .2s ease;
    margin-left: -1.5rem;
    opacity: 0.7;
    &:hover {
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
      transform: scale(1.06);
      opacity: 1;
    }
    &:after {
      content: '';
      display: inline-block;
      background: url(images/arrow-down.png) center no-repeat;
      height: 3rem;
      width: 3rem;
      background-size: 100%;
      opacity: 0.5;
    }
  }

  //messy, but it works
  @media (max-width: 600px) {
    h1 {font-size: 3rem}
    p {font-size: 1.2rem}
    .fancybutton {
      font-size: 1rem;
      padding: 2px 5px;
      margin: 2px 5px;
      .icon {
        font-size: 18px;
        display: inline-block;
      }
    }
  }
}

// section styles
section {
  // padding: 5rem 0.5rem;
}
section.dark {
  color: $text-color-light;
  background: lighten(black, 25%);
  //fix for bad font smoothing in chrome
  -webkit-font-smoothing: antialiased;

  pre code {
    background: lighten(black, 20%);
  }
}

section.light {
  color: $text-color-dark;
  background: #FFFFD9;

  pre code {
    background: #EDDEBA;
    color: #5E584A;
  }
}

section.blue {
  background: #1695A3;
  color: white;
  pre code {
    background: darken(#1695A3, 5%);
  }
}

section.orange {
  background: #EB7F00;
}

section .content {
  @extend .clearfix;
  min-width: 230px;
  width: 700px;
  max-width: 100%;
  margin: 0 auto;
  padding: 4rem 1rem;
  position: relative;

  canvas, img {
    max-width: 100%;
  }
}

.component-colorbrewerdemo {
  margin: 3rem 0;
  text-align: center;
  @extend .clearfix;
  overflow: auto;
  .item {
    float: left;
    width: 33%;
    margin-bottom: 2rem;

    canvas {border-radius: 50%;}
    column-break-inside: none;
  }
}