@font-face {
  font-family: 'icomoon';
  src:url('fonts/icomoon.eot?-sfogva');
  src:url('fonts/icomoon.eot?#iefix-sfogva') format('embedded-opentype'),
    url('fonts/icomoon.woff?-sfogva') format('woff'),
    url('fonts/icomoon.ttf?-sfogva') format('truetype'),
    url('fonts/icomoon.svg?-sfogva#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-spinner11:before {
  content: "\e984";
}

.icon-twitter3:before {
  content: "\ea93";
}

.icon-github:before {
  content: "\eab1";
}

